import { observer } from 'mobx-react-lite';
import { Drawer, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ValuesTable from './table';
import { useViewModel } from '@models/model';
import { IndicatorValueEditViewModel } from './value.model';

interface Props {
    indicatorId: string;
    isVisible: boolean;
    unit?: string;
    onClose: () => void;
    onUpdateIndicatorValue: () => void;
}

export const IndicatorValueEdit: React.FC<Props> = observer(({ indicatorId, isVisible, unit, onClose, onUpdateIndicatorValue }) => {
    const { t } = useTranslation();
    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (description: string) => {
        notifyer.error({
            message: t('common.error.empty'),
            description: description
        });
    };
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(
        () => new IndicatorValueEditViewModel({ indicatorId, t, showError: showErrorNotification, showSuccess: showSuccessNotification })
    );

    const updateValuesWrapper = async (id: number, value: number, is_locked: boolean) => {
        const status = await viewModel.updateValues(id, value, is_locked);
        if (status && viewModel.indicatorValues.length > 0 && viewModel.indicatorValues[0].id === id) {
            // Если обновляется значение первого показателя в списке (за текущий месяц)
            // то заново запрашиваем индикатор, чтобы обновить его значение на странице редактирования индикатора
            // при редактировании остальных показателей можно не обновлять, поскольку на странице отображается только последний
            await onUpdateIndicatorValue();
        }
        return status
    };

    return (
        <Drawer forceRender title={t('indicator.content.indicator_value.edit.title')} width={750} onClose={onClose} open={isVisible} destroyOnClose>
            {contextHolder}
            <ValuesTable
                rows={viewModel.indicatorValues}
                currentPage={viewModel.currentPage}
                pageSize={viewModel.pageSize}
                total={viewModel.dataTotal}
                loading={viewModel.isLoading}
                unit={unit}
                onChangePagination={viewModel.onChangePagination}
                onSave={(id: number, value: number, is_locked: boolean) => updateValuesWrapper(id, value, is_locked)}
            />
        </Drawer>
    );
});

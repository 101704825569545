import { StartupFilterInfo, StartupFilterParamName, StartupGetFilter } from '@interfaces/startup.interface';
import { DictionaryModel, StartupStudioDictionary } from '@models/dictionary-model';
import { StartupProjectData, StartupProjectModel } from '@models/startup-project-model';
import { APIUtilError } from '@utils/api';
import { ObjectLiteral, deleteUndefined, isObjectsHasDifferences } from '@utils/object.utils';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { debounce } from 'lodash';
import { ResultTypeModel } from '@models/result-type-model';
import { ResultModel } from '@models/result-model';
import { MemberModel } from '@models/member-model';
import { FinanceModel } from '@models/finance-model';
import { RidModel } from '@models/rid-model';
import { StartupModel } from '@models/startup-model';

export interface StartupsViewModelStartParams {
    t: any;
    searchParams: URLSearchParams;
    setSearchParams: (params: any) => void;
    showError: (title: string, description: string) => void;
}

export class StartupsViewModel {
    private startupProjectModel = new StartupProjectModel();
    private dictionaryModel = new DictionaryModel();
    private resultTypeModel = new ResultTypeModel();
    private memberModel = new MemberModel();
    private resultModel = new ResultModel();
    private financeModel = new FinanceModel();
    private ridModel = new RidModel();
    private startupModel = new StartupModel();

    public isLoading: boolean = false;

    public currentPage: number = 1;
    public pageSize: number = 20;
    public availablePageSizes = [10, 20, 50, 100];
    public currentFilter: StartupGetFilter = {};

    public currentFilterInfo: StartupFilterInfo[] = [];
    public isCurrentFilterLoading = false;

    public startupsTotal: number = 0;
    public startups: StartupProjectData[] = [];

    public isStartupStudioLoading: boolean = false;
    public startupStudios: StartupStudioDictionary[] = [];

    constructor(private props: StartupsViewModelStartParams) {
        const incomingFilter = this.getFilterFromSearchParams(props.searchParams);
        this.currentFilter = incomingFilter;
        this.currentPage = incomingFilter.page || 0;
        this.pageSize = incomingFilter.page_size || this.availablePageSizes[1];

        makeAutoObservable(this);
        this.wakeUpSir();
    }

    private async wakeUpSir() {
        await this.fetchStartups(this.currentFilter);
        await this.prepareFilterInfo(this.currentFilter);
    }

    public async updateSearchParamsIfNeeded(searchParams: URLSearchParams): Promise<Boolean> {
        const newFilter = this.getFilterFromSearchParams(searchParams);
        if (isObjectsHasDifferences(newFilter, toJS(this.currentFilter))) {
            runInAction(() => {
                this.currentPage = newFilter.page || 1;
                this.pageSize = newFilter.page_size || this.availablePageSizes[1];
                this.currentFilter = newFilter;
            });
            return true;
        }
        return false;
    }

    private getFilterFromSearchParams = (searchParams: URLSearchParams) => {
        let filter: StartupGetFilter = { page: 1, page_size: this.pageSize };
        const keys: StartupFilterParamName[] = [
            'search',
            'result_type_id',
            'result_id',
            'member_id',
            'finance_id',
            'rid_id',
            'startup_id',
            'startup_studio_id',
            'page',
            'page_size'
        ];

        for (const key of keys) {
            const value = searchParams.get(key);
            if (!value) continue;
            switch (key) {
                case 'search':
                    filter[key] = value;
                    break;
                case 'result_type_id':
                case 'result_id':
                case 'member_id':
                case 'finance_id':
                case 'rid_id':
                case 'startup_id':
                case 'startup_studio_id':
                case 'page':
                    try {
                        filter[key] = parseInt(value);
                    } catch {
                        filter[key] = undefined;
                    }
                    break;
                case 'page_size':
                    try {
                        const parsedSize = parseInt(value);
                        if (!this.availablePageSizes.includes(parsedSize)) throw new Error();
                        filter.page_size = parsedSize;
                    } catch {
                        filter.page_size = this.availablePageSizes[0];
                    }
                    break;

                default:
                    break;
            }
        }
        return filter;
    };

    private extractSearchParamsFromFilter = (filter: ObjectLiteral) => {
        let searchParams: ObjectLiteral = {};
        for (const key in filter) {
            switch (key) {
                default:
                    searchParams[key] = filter[key] ? filter[key] : undefined;
            }
        }
        return deleteUndefined(searchParams);
    };

    public filterInfoCount(filter: StartupGetFilter) {
        let count = 0;
        const keys: StartupFilterParamName[] = ['result_type_id', 'result_id', 'member_id', 'finance_id', 'rid_id', 'startup_id'];
        for (const key of keys) {
            const value = filter[key];
            if (!value || value === undefined) continue;
            count++;
        }
        return count;
    }

    public async prepareFilterInfo(filter: StartupGetFilter) {
        runInAction(() => {
            this.isCurrentFilterLoading = true;
        });
        console.log('qweqweqw', filter);

        let info: StartupFilterInfo[] = [];
        const keys: StartupFilterParamName[] = [
            'result_type_id',
            'result_id',
            'member_id',
            'finance_id',
            'rid_id',
            'startup_id',
            'startup_studio_id'
        ];
        for (const key of keys) {
            const value = filter[key];
            if (!value || value === undefined) continue;
            switch (key) {
                case 'result_type_id':
                    try {
                        const result = await this.resultTypeModel.getResultTypeById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('startup_project.filter.result_type_id'),
                            value: result.data.title
                        });
                    } catch {}
                    break;
                case 'result_id':
                    try {
                        const result = await this.resultModel.getById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('startup_project.filter.result_id'),
                            value: result.data.title
                        });
                    } catch {}
                    break;
                case 'member_id':
                    try {
                        const member = await this.memberModel.getMemberById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('startup_project.filter.member_id'),
                            value: `#${member.data.id} ${member.data.guid_putp}`
                        });
                    } catch {}
                    break;
                case 'finance_id':
                    try {
                        const finance = await this.financeModel.getFinanceById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('startup_project.filter.finance_id'),
                            value: `#${finance.data.id} (${this.props.t('finances.content.contract_number.title')}: ${
                                finance.data.contract_number !== null
                                    ? finance.data.contract_number
                                    : this.props.t('finances.content.contract_number.empty')
                            })`
                        });
                    } catch {}
                    break;
                case 'rid_id':
                    try {
                        const rid = await this.ridModel.getRidById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('startup_project.filter.rid_id'),
                            value: rid.data.name
                        });
                    } catch {}
                    break;
                case 'startup_id':
                    try {
                        const startup = await this.startupModel.getStartupById(filter[key]!);
                        info.push({
                            type: key,
                            name: this.props.t('startup_project.filter.startup'),
                            value: startup.data.name
                        });
                    } catch {}
                    break;
                case 'startup_studio_id':
                    await this.fetchStartupStudioById(filter[key]!);
                    break;
                default:
                    break;
            }
        }

        runInAction(() => {
            this.currentFilterInfo = info;
            this.isCurrentFilterLoading = false;
        });
    }

    public onRemoveFilterInfo = (info: StartupFilterInfo) => {
        let filterCopy = this.currentFilter;
        filterCopy[info.type] = undefined;
        this.onFilterValueChange(filterCopy);
    };

    public onFilterValueChange = (values: ObjectLiteral) => {
        let filter: StartupGetFilter = { ...this.currentFilter, ...values, page: 1, page_size: this.pageSize };
        const searchParams = this.extractSearchParamsFromFilter(deleteUndefined(filter));
        this.props.setSearchParams(searchParams as any);

        runInAction(() => {
            this.currentPage = 1;
            this.currentFilter = deleteUndefined(filter);
        });
        this.debouncedFetch(deleteUndefined(filter));
    };

    private debouncedFetch = debounce(async (params: StartupGetFilter) => {
        this.fetchStartups(params);
    }, 400);

    private async fetchStartups(params: StartupGetFilter) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.startupProjectModel.getStartupProjects(params);
            runInAction(() => {
                this.startups = result.data;
                this.startupsTotal = result.total || 0;
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onChangePagination = async (page: number, pageSize: number) => {
        if (pageSize !== this.pageSize) {
            runInAction(() => {
                this.pageSize = pageSize;
            });
        }
        const filter: StartupGetFilter = { ...this.currentFilter, page: page, page_size: pageSize };
        runInAction(() => {
            this.currentPage = page;
            this.currentFilter = filter;
        });

        const currentFiltersSearchParams = this.extractSearchParamsFromFilter(filter);
        this.props.setSearchParams(currentFiltersSearchParams);

        this.fetchStartups(filter);
    };

    private async fetchStartupStudioById(id: string | number) {
        runInAction(() => {
            this.isStartupStudioLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionaryItemById('startup-studio', id);
            runInAction(() => {
                this.startupStudios = result.data as StartupStudioDictionary[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isStartupStudioLoading = false;
            });
        }
    }

    public async searchStartupStudio(search?: string) {
        runInAction(() => {
            this.isStartupStudioLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'startup-studio', search });
            runInAction(() => {
                this.startupStudios = result.data as StartupStudioDictionary[];
            });
        } catch (error) {
            this.props.showError(this.props.t('common.error.fetch'), (error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isStartupStudioLoading = false;
            });
        }
    }
}

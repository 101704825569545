import { Button, Col, Row, Spin, notification, Typography, Table, Collapse, Pagination, Tabs, Badge, Tooltip } from 'antd';
import { DeleteOutlined, RedoOutlined } from '@ant-design/icons';
import '@assets/css/App.css';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { useViewModel } from '@models/model';
import { ReportApiEditViewModel } from './reports-api.edit.view.model';
import { ReportRequest } from '@models/report-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ApiErrorsModal from '@components/UI/api-errors-modal/api-errors-modal.view';
import { useState } from 'react';


const ReportApiEditView: React.FC = observer(() => {
    const { t } = useTranslation();
    const { id: operatorAPIId } = useParams();
    const navigate = useNavigate();

    const [modalErrors, setModalErrors] = useState(false);

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };

    const onFinish = () => {
        onClickCancel();
    };

    const onClickCancel = () => {
        navigate('/reports-api-manage');
    };

    const viewModel = useViewModel(
        () =>
            new ReportApiEditViewModel({
                t,
                operatorAPIId,
                showError: showErrorNotification,
                onFinish
            })
    );

    const revokeRequest = (e: any, reqId: number, revoke: boolean) => {
        e.stopPropagation();
        viewModel.revokeRequest(reqId, revoke);
    };

    const genExtra = (i: ReportRequest) => {
        dayjs.extend(utc);
        const date = dayjs(i.created_at);
        if (!date.isValid()) return <Typography.Text>-</Typography.Text>;
        return (
            <Row gutter={12} justify="space-between" align="middle">
                <Col>
                    {date.isValid() && <Typography.Text strong>{date.format('DD.MM.YYYY в HH:mm')}</Typography.Text>}
                    {!date.isValid() && <Typography.Text>-</Typography.Text>}
                </Col>
                <Col>|</Col>
                <Col>
                    {i.count_errors === 0 ? (
                        <Badge status="processing" color="primary" text={t('reports-api.errors.no')} />
                    ) : (
                        <Badge status="error" text={`${t('reports-api.errors.yes')} (${i.count_errors})`} />
                    )}
                </Col>
                {(i.status === 'Получен, не обработан' || i.status === 'Пройдена первичная проверка') && (
                    <>
                        <Col>|</Col>
                        <Col>
                            <Tooltip title={t('reports-api.errors.revoke')}>
                                <Button icon={<DeleteOutlined />} onClick={(e) => revokeRequest(e, i.id, true)} size="small" />
                            </Tooltip>
                        </Col>
                    </>
                )}
                {i.status === 'Отозвано' && (
                    <>
                        <Col>|</Col>
                        <Col>
                            <Tooltip title={t('reports-api.errors.cancel_revoke')}>
                                <Button icon={<RedoOutlined />} onClick={(e) => revokeRequest(e, i.id, false)} size="small" />
                            </Tooltip>
                        </Col>
                    </>
                )}
            </Row>
        );
    };

    const getTabsItems = () => {
        return Object.keys(viewModel.reportSchema.schema).map((i: string) => {
            return {
                key: i,
                label: i,
                children: (
                    <div style={{ width: '100%', overflowX: 'auto' }}>
                        <Table
                            size="small"
                            loading={viewModel.isLoadingRequest}
                            bordered
                            dataSource={viewModel.reportRequestData.data ? viewModel.reportRequestData.data[i] : []}
                            columns={Object.keys(viewModel.reportSchema.schema[i][0]).map((i: string) => {
                                return { title: i, dataIndex: i };
                            })}
                            rowClassName="row"
                            rowKey={'id'}
                            pagination={false}
                        />
                    </div>
                )
            };
        });
    };

    const requests: any = viewModel.reportRequests.map((i: ReportRequest) => {
        return {
            key: i.id,
            label: i.status,
            children: (
                <div>
                    {i.count_errors !== undefined && i.count_errors > 0 && (
                        <div>
                            <Button onClick={() => setModalErrors(true)} danger>
                                {t('reports-api.errors.show_list')}
                            </Button>
                            <ApiErrorsModal apiDataStorageId={i.id} showModal={modalErrors} closeModal={() => setModalErrors(false)} />
                        </div>
                    )}
                    <Tabs activeKey={viewModel.reqDataFisrtkey} items={getTabsItems()} onTabClick={(key, e) => (viewModel.reqDataFisrtkey = key)} />
                </div>
            ),
            extra: genExtra(i)
        };
    })

    const onChangeRequestsPagination = async (page: number, pageSize: number) => {
        await viewModel.onChangeRequestsPagination(page, pageSize);
    };


    const onClickRequest = async (key: string | string[]) => {
        if (key.length) await viewModel.getReportRequestsData(parseInt(key[0]));
    };

    return (
        <div>
            {contextHolder}
            <br />
            <Row gutter={12} justify="space-between" align="middle">
                <Col>
                    <Typography.Title>{viewModel.report.name}</Typography.Title>
                </Col>
                <Col>
                    <Button onClick={onClickCancel}>{t('common.cancel')}</Button>
                </Col>
            </Row>
            <br />
            <div className="edit-content-view">
                <Spin spinning={viewModel.isLoading}>
                    {requests.length !== 0 && <Collapse items={requests} onChange={onClickRequest} accordion={true}></Collapse>}
                    {requests.length === 0 && <Typography.Text>{t('reports-api.noData')}</Typography.Text>}
                </Spin>
            </div>
            <br />
            <Row gutter={12} justify="center">
                <Col>
                    <Pagination
                        total={viewModel.reportRequestsTotal}
                        pageSize={viewModel.reportRequestsLimit}
                        current={viewModel.reportRequestsCurPage}
                        onChange={onChangeRequestsPagination}
                    />
                </Col>
            </Row>
        </div>
    );
});

export default ReportApiEditView;

import { observer } from 'mobx-react-lite';
import React from 'react';
import { Col, Divider, Row, Typography, notification } from 'antd';
import { useViewModel } from '@models/model';
import { useTranslation } from 'react-i18next';
import { ResultTypesViewModel } from './result-types.view.model';
import { ContentTable } from './table';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ResultTypeData } from '@models/result-type-model';
import { windowOpen } from '@utils/window.utils';

const ResultTypesView: React.FC = observer(() => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (title: string, description: string) => {
        notifyer.error({
            message: title,
            description: description
        });
    };
    const viewModel = useViewModel(
        () =>
            new ResultTypesViewModel({
                t,
                showError: showErrorNotification
            })
    );

    const onEdit = (id: number) => {
        windowOpen(`/result-types-manage/edit/${id || ''}`);
    };

    const onShowMembers = (resultType: ResultTypeData) => {
        if (!resultType.id) return;
        navigate({
            pathname: '/members-manage',
            search: createSearchParams({
                result_type_id: `${resultType.id}`
            }).toString()
        });
    };

    const onShowResults = (resultType: ResultTypeData) => {
        if (!resultType.id) return;
        navigate({
            pathname: 'results-manage',
            search: createSearchParams({
                result_type_id: `${resultType.id}`
            }).toString()
        });
    };
    const onShowEvents = (resultType: ResultTypeData) => {
        if (!resultType.id) return;
        navigate({
            pathname: '/events-manage',
            search: createSearchParams({
                result_type_id: `${resultType.id}`
            }).toString()
        });
    };

    const onShowStartups = (resultType: ResultTypeData) => {
        if (!resultType.id) return;
        navigate({
            pathname: '/startup-project-manage',
            search: createSearchParams({
                result_type_id: `${resultType.id}`
            }).toString()
        });
    };

    const onShowRids = (resultType: ResultTypeData) => {
        if (!resultType.id) return;
        navigate({
            pathname: '/rids-manage',
            search: createSearchParams({
                result_type_id: `${resultType.id}`
            }).toString()
        });
    };

    const onShowFinances = (resultType: ResultTypeData) => {
        if (!resultType.id) return;
        navigate({
            pathname: '/finances-manage',
            search: createSearchParams({
                result_type_id: `${resultType.id}`
            }).toString()
        });
    };

    return (
        <div>
            {contextHolder}
            <Row gutter={12} align="middle" justify="space-between">
                <Col>
                    <Typography.Title>{t('result_types.title')}</Typography.Title>
                </Col>
            </Row>

            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <br />
            <ContentTable
                currentPage={viewModel.currentPage}
                pageSize={viewModel.pageSize}
                rows={viewModel.resultTypes}
                onEdit={(resultType: ResultTypeData) => onEdit(resultType.id)}
                onDelete={viewModel.delete}
                onShowMembers={onShowMembers}
                onShowResults={onShowResults}
                onShowEvents={onShowEvents}
                onShowStartups={onShowStartups}
                onShowRids={onShowRids}
                onShowFinances={onShowFinances}
                loading={viewModel.loading}
                total={viewModel.resultTypesCount}
            />
            <br />
        </div>
    );
});

export default ResultTypesView;

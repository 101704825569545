import { useState } from 'react';
import { Table, Form, Typography, Tooltip, Button } from 'antd';
import { useEffect } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import 'katex/dist/katex.min.css';
import { Indicator } from '@models/indicator-model';

interface TableProps {
    rows: Indicator[];
    loading: boolean;
    onEdit: (item: Indicator) => void;
}

export const DataTable = (props: TableProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [data, setData] = useState<Indicator[]>([]);

    useEffect(() => {
        setData(props.rows);
    }, [props]);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: t('indicator.content.title.title'),
            dataIndex: 'title'
        },
        {
            title: t('indicator.content.description.title'),
            dataIndex: 'description'
        },
        {
            title: t('indicator.content.indicator_value.title'),
            render: (_: any, record: Indicator) => {
                return (
                    <span>
                        <Typography.Text strong>{record.indicator_value}</Typography.Text>
                    </span>
                );
            }
        },
        {
            title: t('indicator.content.unit.title'),
            dataIndex: 'unit'
        },
        {
            title: '',
            width: '10%',
            dataIndex: 'operations',
            render: (_: any, record: Indicator) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title={t('common.edit')}>
                            <Button onClick={() => props.onEdit(record)}>
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                    </div>
                );
            }
        }
    ];

    return (
        <Form form={form} component={false}>
            <Table
                size="small"
                loading={props.loading}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="row"
                rowKey={'id'}
                pagination={false}
            />
        </Form>
    );
};

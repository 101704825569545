import { DictionaryModel, MonthDictionaryData, ResultTypeDictionaryData } from '@models/dictionary-model';
import { IndicatorAdditionalValue, IndicatorModel } from '@models/indicator-model';
import { APIUtilError } from '@utils/api';
import { debounce } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';

interface Props {
    indicatorId: string;
    tag?: string;
    resultTypes: ResultTypeDictionaryData[];
    t: any;
    showSuccess: (title: string, description: string) => void;
    showError: (description: string) => void;
}

export class AdditionalValuesViewModel {
    private indicatorModel = new IndicatorModel();
    private dictionaryModel = new DictionaryModel();
    public isLoading: boolean = false;

    public currentPage: number = 1;
    public pageSize: number = 20;
    public dataTotal: number = 0;

    private _additionalValues: IndicatorAdditionalValue[] = [];
    public get additionalValues(): IndicatorAdditionalValue[] {
        return this._additionalValues;
    }

    private _currentTag: string;
    public get currentTag(): string {
        return this._currentTag;
    }
    public set currentTag(tag: string) {
        runInAction(() => {
            this._currentTag = tag;
        });
        this.fetchValues(tag, this.currentPage, this.pageSize);
    }

    private _createActive = false;
    public get createActive(): boolean {
        return this._createActive;
    }
    public toggleCreate() {
        runInAction(() => {
            this._createActive = !this._createActive;
        });
    }

    public monthsDictionary: MonthDictionaryData[] = [];
    public countMonths: number = 0;
    public isMonthsLoading: boolean = false;

    constructor(private startParams: Props) {
        makeAutoObservable(this);
        this.fetchMonthsDictionary();
    }

    private async fetchValues(tag: string, page: number, page_size: number) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const result = await this.indicatorModel.getAdditionalValues({ indicator_id: this.startParams.indicatorId, tag: tag, page, page_size });
            runInAction(() => {
                this._additionalValues = result.data;
                this.dataTotal = result.total;
            });
        } catch (error) {
            this.startParams.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    public onChangePagination = async (page: number, pageSize: number) => {
        runInAction(() => {
            this.currentPage = page;
            this.pageSize = pageSize;
        });
        this.fetchValues(this.currentTag, page, pageSize);
    };

    public async createValue(add_value: number, is_active: boolean, month_id: number): Promise<boolean> {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            const created = await this.indicatorModel.createAdditionalValue({
                indicator_id: this.startParams.indicatorId,
                add_value,
                is_active,
                month_id,
                tag: this.currentTag
            });
            this.startParams.showSuccess(this.startParams.t('common.saved'), '');
            let updated = [...this._additionalValues, created.data];
            updated = updated.sort((a, b) => b.month_id - a.month_id);
            runInAction(() => {
                this._additionalValues = updated;
                this.isLoading = false;
                if (this.monthsDictionary.length < this.countMonths) {
                    this.fetchMonthsDictionary();
                }
            });
            this.toggleCreate();
            return true;
        } catch (error) {
            this.startParams.showError((error as APIUtilError).localizedDescription);
            runInAction(() => {
                this.isLoading = false;
            });
            return false;
        }
    }

    public async updateValue(id: number, add_value: number, is_active: boolean): Promise<boolean> {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.indicatorModel.updateAdditionalValue(id, { add_value, is_active });
            runInAction(() => {
                this.isLoading = false;
            });
            this.startParams.showSuccess(this.startParams.t('common.saved'), '');
            return true;
        } catch (error) {
            this.startParams.showError((error as APIUtilError).localizedDescription);
            runInAction(() => {
                this.isLoading = false;
            });
            return false;
        }
    }

    public async deleteValue(id: number): Promise<boolean> {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            await this.indicatorModel.deleteAdditionalValue(id);
            const updated = [...this._additionalValues].filter((item) => item.id !== id);
            runInAction(() => {
                this.isLoading = false;
                this._additionalValues = updated;
            });
            return true;
        } catch (error) {
            this.startParams.showError((error as APIUtilError).localizedDescription);
            runInAction(() => {
                this.isLoading = false;
            });
            return false;
        }
    }

    public async searchMonths(search: string) {
        await debounce(async () => this.fetchMonthsDictionary(search.length > 0 ? search : undefined), 500)();
    }

    private async fetchMonthsDictionary(search?: string) {
        runInAction(() => {
            this.isMonthsLoading = true;
        });
        try {
            const result = await this.dictionaryModel.getDictionary({ altname: 'month', search, page_size: 100 });
            runInAction(() => {
                this.monthsDictionary = result.data as MonthDictionaryData[];
                if (this.countMonths === 0) {
                    this.countMonths = result.data.length;
                }
            });
        } catch (error) {
            this.startParams.showError((error as APIUtilError).localizedDescription);
        } finally {
            runInAction(() => {
                this.isMonthsLoading = false;
            });
        }
    }
}

import { SearchOutlined } from '@ant-design/icons';
import { Alert, Button, Input, Modal, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import crypto from 'crypto-js';
interface Props {
    guid?: string;
    onSearch: (guid?: string) => void;
}
export const GUIDForm: React.FC<Props> = ({ guid: propsGuid, onSearch }) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSearchValid, setIsSearchValid] = useState(false);
    const [guid, setGuid] = useState<string | undefined>();

    useEffect(() => {
        setGuid(propsGuid);
    }, [propsGuid]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        let guid = searchValue;

        if (_isSnilsValid(searchValue)) {
            guid = crypto.MD5(String(searchValue).replace(/[^0-9]+/g, '')).toString();
        }
        setGuid(guid);
        onSearch(guid);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleDelete = () => {
        setGuid(undefined);
        onSearch();
    };

    const onChangeSnils = (event: any) => {
        const snils = event.target.value;
        setSearchValue(snils);

        const isSnilsValid = _isSnilsValid(snils);
        const isMD5Valid = _isMD5Valid(snils);

        if (!isSearchValid && (isSnilsValid || isMD5Valid)) setIsSearchValid(true);
        if (isSearchValid && !(isSnilsValid || isMD5Valid)) setIsSearchValid(false);
    };

    return (
        <div>
            {guid && (
                <div>
                    <Tag closeIcon onClose={handleDelete} style={{ fontSize: 16, padding: 4 }}>
                        {guid}
                    </Tag>
                </div>
            )}
            {!guid && (
                <div>
                    <Button style={{ width: '100%' }} icon={<SearchOutlined />} onClick={showModal}>
                        {t('common.search')}
                    </Button>
                </div>
            )}
            <Modal
                title="GUID"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={t('common.search')}
                okButtonProps={{ disabled: !isSearchValid }}
            >
                <Alert message={t('members.filter.searchGuid.info')} type="info" showIcon />
                <br />

                <Input style={{ width: '100%' }} onChange={onChangeSnils} value={searchValue} status={isSearchValid ? undefined : 'error'} />
            </Modal>
        </div>
    );
};

function _isSnilsValid(snils: string) {
    snils = String(snils).replace(/[^0-9]+/g, '');
    if (snils.length === 11) {
        var checksum = 0;
        for (var i = 0; i < 9; i++) {
            checksum += parseInt(snils.charAt(i)) * (9 - i);
        }
        if (checksum > 101) {
            checksum = checksum % 101;
        }
        if (checksum === 100 || checksum === 101) {
            checksum = 0;
        }
        return checksum === parseInt(snils.substr(9));
    }
    return false;
}

function _isMD5Valid(md5: string) {
    const pattern = /^[a-fA-F0-9]{32}$/;
    return pattern.test(md5);
}

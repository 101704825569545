import { observer } from 'mobx-react-lite';
import { Button, Drawer, Empty, Form, InputNumber, notification, Select, Switch, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import ValuesTable from './table';
import { useViewModel } from '@models/model';
import { AdditionalValuesViewModel } from './additional-values.model';
import { ResultTypeDictionaryData } from '@models/dictionary-model';
import { CloseOutlined, PlusOutlined, SaveOutlined, UnlockOutlined } from '@ant-design/icons';
import { IndicatorFormulaPart } from '@models/indicator-model';

interface Props {
    indicatorId: string;
    tag?: string;
    formula?: IndicatorFormulaPart[];
    unit?: string;
    resultTypes: ResultTypeDictionaryData[];
    onClose: () => void;
    onCreateDelete: () => void;
}

export const AdditionalValuesEdit: React.FC<Props> = observer(({ indicatorId, tag, formula, resultTypes, unit, onClose, onCreateDelete }) => {
    const { t } = useTranslation();
    const [createForm] = Form.useForm();
    const [notifyer, contextHolder] = notification.useNotification();
    const showErrorNotification = (description: string) => {
        notifyer.error({
            message: t('common.error.empty'),
            description: description
        });
    };
    const showSuccessNotification = (title: string, description: string) => {
        notifyer.success({
            message: title,
            description: description
        });
    };

    const viewModel = useViewModel(
        () =>
            new AdditionalValuesViewModel({
                indicatorId,
                tag,
                resultTypes,
                t,
                showError: showErrorNotification,
                showSuccess: showSuccessNotification
            })
    );

    useEffect(() => {
        if (tag) {
            viewModel.currentTag = tag;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tag]);

    const getResultTypeSelectorItems = () => {
        if (formula) {
            // Фильтруем resultTypes - оставляем только те, которые используются в формуле
            const usedTags = formula.filter((i) => i.type === 'value').map((i) => i.tag);
            resultTypes = resultTypes.filter((i) => usedTags.includes(i.tag));
        } 
        let resultTypeTags: Array<{ value: string; label: string }> = resultTypes.map((item) => {
            return {
                value: item.tag,
                label: `${item.alt_title} (${item.tag})`
            };
        });
        return resultTypeTags;
    };

    const onSuccessFormValidation = async () => {
        const data = createForm.getFieldsValue();
        const result = await viewModel.createValue(data.add_value, data.is_active, data.month_id);
        if (result) {
            createForm.resetFields();
        }
        onCreateDelete();
    };

    const deleteValueWrapper = async (id: number) => {
        const status = await viewModel.deleteValue(id);
        if (status) {
            onCreateDelete();
        }
        return status;
    };

    return (
        <Drawer forceRender title={t('indicator.content.indicator_additional_value.title')} width={750} onClose={onClose} open={!!tag} destroyOnClose>
            {contextHolder}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 5 }}>
                <Typography.Text>{t('indicator.content.indicator_formula.tag.title')}:</Typography.Text>
                <Select
                    style={{ width: '95%' }}
                    options={getResultTypeSelectorItems()}
                    value={viewModel.currentTag}
                    onChange={(value) => (viewModel.currentTag = value)}
                />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 20 }}>
                {!viewModel.createActive && (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-end'
                        }}
                    >
                        <Button icon={<PlusOutlined />} onClick={() => viewModel.toggleCreate()}>
                            Создать
                        </Button>
                        <div style={{ width: '100%', height: '100%', marginTop: 10 }}>
                            <ValuesTable
                                rows={viewModel.additionalValues}
                                currentPage={viewModel.currentPage}
                                pageSize={viewModel.pageSize}
                                total={viewModel.dataTotal}
                                loading={viewModel.isLoading}
                                unit={unit}
                                onChangePagination={viewModel.onChangePagination}
                                onSave={(id: number, value: number, is_active: boolean) => viewModel.updateValue(id, value, is_active)}
                                onDelete={(id: number) => deleteValueWrapper(id)}
                            />
                        </div>
                    </div>
                )}

                {viewModel.createActive && (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            marginTop: 20
                        }}
                    >
                        <div style={{ width: '50%', height: '100%' }}>
                            <Form
                                layout="vertical"
                                form={createForm}
                                requiredMark={true}
                                initialValues={{ add_value: 0, is_active: false }}
                                onFinish={onSuccessFormValidation}
                            >
                                <Form.Item
                                    name={'month_id'}
                                    label={t('indicator.content.indicator_additional_value.edit.month')}
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        showSearch
                                        allowClear
                                        notFoundContent={<Empty description={''} />}
                                        placeholder={t('indicator.content.indicator_additional_value.edit.month_search')}
                                        onSearch={(value: string) => viewModel.searchMonths(value)}
                                        loading={viewModel.isMonthsLoading}
                                        filterOption={false}
                                    >
                                        {viewModel.monthsDictionary.map((item, i) => (
                                            <Select.Option value={item.id} key={`month_${item.id}`}>
                                                <Typography.Text>
                                                    {item.name} {item.year}
                                                </Typography.Text>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name={'add_value'}
                                    label={`${t('indicator.content.indicator_additional_value.edit.value')} (${unit})`}
                                    rules={[{ required: true }]}
                                >
                                    <InputNumber style={{ width: '100%' }} min={0} />
                                </Form.Item>

                                <Form.Item
                                    name="is_active"
                                    label={t('indicator.content.indicator_additional_value.edit.isActive.title')}
                                    tooltip={t('indicator.content.indicator_additional_value.edit.isActive.tooltip')}
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren={<UnlockOutlined />} />
                                </Form.Item>
                            </Form>
                        </div>
                        <div style={{ display: 'flex', gap: 5 }}>
                            <Button icon={<SaveOutlined />} type="primary" onClick={() => createForm.submit()}>
                                Сохранить
                            </Button>
                            <Button icon={<CloseOutlined />} onClick={() => viewModel.toggleCreate()}>
                                Отмена
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Drawer>
    );
});
